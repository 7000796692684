import React, {Component} from 'react';
import PropTypes from "prop-types";
import Domino from "./Domino";

const propTypes = {
    dominoStack: PropTypes.array,
    chooseDomino: PropTypes.func
};

class DominoStack extends Component {

    renderDominoStack() {
        return this.props.dominoStack.map((domino, index) => {
           return(<Domino domino={domino} key={index} chooseDomino={this.props.chooseDomino}/>);
        });
    }

    render() {
        const {dominoStack} = this.props;
        if (dominoStack) {
            return (
                <div className="DominoStack">
                    {this.renderDominoStack()}
                </div>
            );
        } else {
            return(null);
        }
    }
}

DominoStack.propTypes = propTypes;
export default DominoStack;
