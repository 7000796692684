import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers/simpleReducer';
import rootSaga from './reducers/sagas';
import {BEFORE_START} from "./model/GameStates";


// create the saga middleware
const sagaMiddleware = createSagaMiddleware();


const initialState = {gameState: BEFORE_START};

const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(sagaMiddleware)
);

// then run the saga
sagaMiddleware.run(rootSaga);


export default function configureStore() {
    return store
}