import React, {Component} from 'react';
import './App.css';
import DiceStack from './components/DiceStack';
import {connect} from "react-redux";
import {
    bustConfirm,
    chooseDice, chooseDomino,
    chooseNumberOfPlayers,
    getHighScores,
    newGame,
    newPlayerTurnConfirm,
    throwDice
} from './actions/actions';
import DicesAside from "./components/DicesAside";
import GameStatus from "./components/GameStatus";
import {BEFORE_START} from "./model/GameStates";
import NewPlayerTurn from "./components/NewPlayerTurn";
import PlayerContainer from "./components/PlayerContainer";
import DominoStack from "./components/DominoStack";
import TurnBust from "./components/TurnBust";

const mapStateToProps = state => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    throwDice: (nrOfDice) => dispatch(throwDice(nrOfDice)),
    chooseDice: (dice) => dispatch(chooseDice(dice)),
    getHighScores: () => dispatch(getHighScores()),
    newGame: () => dispatch(newGame()),
    chooseNumberOfPlayers: (nrOfPlayers) => dispatch(chooseNumberOfPlayers(nrOfPlayers)),
    newPlayerTurnConfirm: () => dispatch(newPlayerTurnConfirm()),
    bustConfirm: () => dispatch(bustConfirm()),
    chooseDomino: (index) => dispatch(chooseDomino(index))
});

class App extends Component {

    componentDidMount() {
        if(this.props.gameState === BEFORE_START) {
            this.props.getHighScores();
        }
    }

    render() {
        return (
            <div className="App">
                <GameStatus status={this.props.gameState}
                            highScores={this.props.highScores}
                            newGame={this.props.newGame}
                            chooseNumberOfPlayers={this.props.chooseNumberOfPlayers}
                />

                <NewPlayerTurn status={this.props.gameState} confirm={this.props.newPlayerTurnConfirm} playerNumber={this.props.indexOfPlayerAtTurn}/>
                <TurnBust status={this.props.gameState} confirm={this.props.bustConfirm}/>

                <h1>Regenwormen</h1>

                <DominoStack dominoStack={this.props.dominoStack} chooseDomino={this.props.chooseDomino}/>

                <div className="ElementContainer">
                    <DicesAside dices={this.props.dicesAside} diceTotalScore={this.props.dicesAsideScore}/>

                    <DiceStack dices={this.props.diceStack} chooseDice={this.props.chooseDice}/>
                </div>


                <button disabled={this.props.gameState === 'DICES_THROWN'}
                        onClick={() => this.props.throwDice(this.props.diceStack.length)}>Gooi met de dobbelstenen
                </button>

                <PlayerContainer players={this.props.players} indexPlayerWhosTurn={this.props.indexOfPlayerAtTurn}/>

                <br/>
                {this.props.gameState}

                {/*<pre>*/}
                {/*{JSON.stringify(this.props)}*/}
                {/*</pre>*/}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
