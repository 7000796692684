class DominoObj {
    constructor(index, label, value, canBeChosen) {
        this._index = index;
        this._label = label;
        this._value = value;
        this._canBeChosen = canBeChosen;
    }

    get index() {
        return this._index;
    }

    get label() {
        return this._label;
    }

    get value() {
        return this._value;
    }

    get canBeChosen() {
        return this._canBeChosen;
    }
}
export default DominoObj;