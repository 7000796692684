import React, { Component } from 'react';
import Dice from "./Dice";
import PropTypes from 'prop-types';

const propTypes = {
    dices: PropTypes.array,
    chooseDice: PropTypes.func,
};

class DiceStack extends Component {

    renderDices(dices) {
        if (dices) {
            return dices.map((dice, index) => {
                return(
                    <Dice dice={dices[index]} chooseDice={this.props.chooseDice} key={index}/>
                );
            });
        }
    }

    render() {
        return (
            <div className="DiceStack">
                {this.renderDices(this.props.dices)}

                {/*<pre>*/}
                    {/*{JSON.stringify(this.props)}*/}
                {/*</pre>*/}
            </div>

        );
    }
}

DiceStack.propTypes = propTypes;
export default DiceStack;
