import React, { Component } from 'react';
import PropTypes from "prop-types";
import {BEFORE_START, CHOOSE_PLAYERS} from "../model/GameStates";
import HighScore from "./HighScore";

const propTypes = {
    status: PropTypes.string,
    chooseNumberOfPlayers: PropTypes.func,
};

class ChoosePlayers extends Component {

    renderChooseNumbers() {
        const result = [];
        for (let i = 2; i <= 8; i++) {
            result.push(
                <button className="ChoosePlayerNumber"
                     key={i}
                    onClick={() => this.props.chooseNumberOfPlayers(i)}
                >{i}</button>
            );
        }
        return result;
    }

    render() {
        if (this.props.status === CHOOSE_PLAYERS) {
            return (
                <div>
                    <h1>Kies aantal spelers</h1>
                    {this.renderChooseNumbers()}
                </div>
            );
        } else {
            return(null);
        }

    }
}

ChoosePlayers.propTypes = propTypes;
export default ChoosePlayers;
