class PlayerObj {
    constructor(index) {
        this.index = index;
        this.dominos = [];
    }

    getIndex() {
        return this.index;
    }

    getNumber() {
        return this.index + 1;
    }

    getDominos() {
        return this.dominos;
    }

    putDomino(domino) {
        this.dominos[this.dominos.length] = domino;
    }
}
export default PlayerObj;