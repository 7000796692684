import React, { Component } from 'react';
import PropTypes from "prop-types";
import {NEW_PLAYER_TURN} from "../model/GameStates";

const propTypes = {
    status: PropTypes.string,
    confirm: PropTypes.func,
    playerNumber: PropTypes.number
};

class NewPlayerTurn extends Component {

    render() {
        if (this.props.status === NEW_PLAYER_TURN) {
            return (
                <div className="PlayerTurnModal">
                    <h1>Speler {this.props.playerNumber+1}</h1>
                    <button onClick={this.props.confirm}>OK</button>
                </div>

            );
        } else {
            return(null);
        }
    }
}

NewPlayerTurn.propTypes = propTypes;
export default NewPlayerTurn;
