import React, { Component } from 'react';
import PropTypes from "prop-types";
import DiceObj from '../model/DiceObj';

const propTypes = {
    dice: PropTypes.instanceOf(DiceObj),
    chooseDice: PropTypes.func,
};

class Dice extends Component {

    render() {
        const {chooseDice,dice} = this.props;
        return (
            <div id={dice.getId()} className={'Dice ' + dice.getClassName() + ' chosen' + dice.getCanBeChosen()}
                 onClick={() => { chooseDice(dice) }}>
            </div>
        );
    }
}

Dice.propTypes = propTypes;
export default Dice;
