import React, {Component} from 'react';
import PropTypes from "prop-types";
import DominoObj from '../model/DominoObj';

const propTypes = {
    domino: PropTypes.instanceOf(DominoObj),
    chooseDomino: PropTypes.func
};

class Domino extends Component {

    render() {
        const {domino,chooseDomino} = this.props;
        return (
            <div className={`Domino DominoCanBeChosen${domino.canBeChosen}`} onClick={() => chooseDomino(domino.index)}>
                <h2>{domino.label}</h2>
                <span className="DominoWormValue">{domino.value}</span>
            </div>

        );
    }
}

Domino.propTypes = propTypes;
export default Domino;
