import {
    BUST_CONFIRM,
    CHOOSE_DICE, CHOOSE_DOMINO,
    CHOOSE_NUMBER_OF_PLAYERS,
    HIGH_SCORES_FETCHED,
    NEW_GAME, NEW_PLAYER_TURN_CONFIRM,
    THROW_DICE
} from '../actions/actions'
import DiceObj from '../model/DiceObj';
import PlayerObj from '../model/PlayerObj';
import {containsDiceType,containsSelectableDices} from '../components/DiceHelper';
import {
    AWAIT_PLAYER,
    BEFORE_START,
    CHOOSE_PLAYERS,
    DICES_THROWN,
    GAME_FINISHED,
    NEW_PLAYER_TURN, TURN_BUST
} from "../model/GameStates";
import DominoObj from "../model/DominoObj";

export default (state = {dicesAside: [], gameState: BEFORE_START}, action) => {
    switch (action.type) {
        case THROW_DICE: {
            if (!state.gameState || state.gameState === AWAIT_PLAYER) {
                const newDiceStack = [];
                for (let i=0; i < action.diceIndexes.length; i++) {
                    const diceIndex = action.diceIndexes[i];
                    const canBeChosen = state.dicesAside ? !containsDiceType(state.dicesAside, diceIndex) : true;
                    newDiceStack[newDiceStack.length] = new DiceObj('dice'+i, diceIndex, canBeChosen);
                }
                const gameState = containsSelectableDices(newDiceStack) ? DICES_THROWN : TURN_BUST;
                return Object.assign({}, state, {
                    diceStack: newDiceStack,
                    gameState: gameState,
                });
            } else {
                return state;
            }
        }
        case CHOOSE_DICE: {
            // first check if can be chosen; if not, do nothing
            if (!action.dice.getCanBeChosen()) return state;

            // iterate through diceStack and move dice values to dices aside
            const newDiceStack = [];
            const newDicesAside = state.dicesAside ? state.dicesAside.slice() : [];
            let newDicesAsideScore = state.dicesAsideScore ? state.dicesAsideScore : 0;

            for (let i=0; i < state.diceStack.length; i++) {
                const dice = state.diceStack[i];
                if (state.diceStack[i].getType() === action.dice.getType()) {
                    // put dice aside
                    newDicesAside[newDicesAside.length] = dice;
                    // add value
                    newDicesAsideScore += state.diceStack[i].getValue();
                } else {
                    // ~Stays in the stack -> back in the new copy of the stack
                    newDiceStack[newDiceStack.length] = new DiceObj(dice.getId(), dice.getType(), false);
                }
            }

            let asideContainsWorm = false;
            for (let i = 0; i < newDicesAside.length; i++) {
                if (newDicesAside[i].isWorm()) {
                    asideContainsWorm = true;
                    break;
                }
            }

            let newDominoStack = state.dominoStack;

            if (newDicesAsideScore >= 21 && asideContainsWorm) {
                newDominoStack = [];
                for (let i=0; i < state.dominoStack.length; i++) {
                    const domino = state.dominoStack[i];
                    const canBeChosen = domino.label <= newDicesAsideScore;
                    newDominoStack[i] = new DominoObj(domino.index, domino.label, domino.value, canBeChosen);
                }
            }

            return Object.assign({}, state, {
                dicesAside: newDicesAside,
                dicesAsideScore: newDicesAsideScore,
                diceStack: newDiceStack,
                dominoStack: newDominoStack,
                gameState: AWAIT_PLAYER,
            });
        }
        case CHOOSE_DOMINO: {
            const newDominoStack = [];
            const currentPlayer = state.players[state.indexOfPlayerAtTurn];

            for (let i=0; i < state.dominoStack.length; i++) {
                const domino = state.dominoStack[i];
                if (domino.index === action.data) {
                    // Add to player
                    currentPlayer.putDomino(new DominoObj(domino.index, domino.label, domino.value, false));
                } else {
                    // ~ Put back; in new dominoStack
                    newDominoStack[newDominoStack.length] = new DominoObj(domino.index, domino.label, domino.value, false);
                }
            }

            let nextPlayerIndex = state.indexOfPlayerAtTurn+1;
            if (nextPlayerIndex >= state.players.length) nextPlayerIndex = 0;

            return Object.assign({}, state, {
                dominoStack: newDominoStack,
                indexOfPlayerAtTurn: nextPlayerIndex,
                gameState: NEW_PLAYER_TURN,
                dicesAside: [],
                dicesAsideScore: 0,
            });
        }
        case BUST_CONFIRM: {
            let nextPlayerIndex = state.indexOfPlayerAtTurn+1;
            if (nextPlayerIndex >= state.players.length) nextPlayerIndex = 0;
            return Object.assign({}, state, {
                gameState: NEW_PLAYER_TURN,
                indexOfPlayerAtTurn: nextPlayerIndex,
                dicesAside: [],
                dicesAsideScore: 0,
            });
        }
        case HIGH_SCORES_FETCHED: {
            return Object.assign({}, state, {
                highScores: action.data,
            });
        }
        case NEW_GAME: {
            return Object.assign({}, state, {
                gameState: CHOOSE_PLAYERS,
                dominoStack: action.dominoStack
            });
        }
        case CHOOSE_NUMBER_OF_PLAYERS: {
            const players = [];
            for (let i = 0; i < action.data; i++) {
                players[i] = new PlayerObj(i);
            }
            return Object.assign({}, state, {
                nrOfPlayers: action.data,
                gameState: NEW_PLAYER_TURN,
                indexOfPlayerAtTurn: 0,
                players: players
            });
        }
        case NEW_PLAYER_TURN_CONFIRM: {
            return Object.assign({}, state, {
                gameState: AWAIT_PLAYER,
                diceStack: action.diceStack
            });
        }
        default:
            return state
    }
}