import React, { Component } from 'react';
import PropTypes from "prop-types";
import {BEFORE_START} from "../model/GameStates";
import HighScore from "./HighScore";

const propTypes = {
    status: PropTypes.string,
    highScores: PropTypes.array,
    newGame: PropTypes.func
};

class HallOfFame extends Component {

    renderHighScorePlayers(highScores) {
        if (highScores) {
            return highScores.map((player, index) => {
                return (<HighScore score={player} key={index}/>);
            });
        }
    }

    render() {
        if (this.props.status === BEFORE_START) {
            return (
                <div>
                    <h1>Hall of Fame</h1>
                    {this.renderHighScorePlayers(this.props.highScores)}
                    <button onClick={this.props.newGame}>Nieuw spel</button>
                </div>
            );
        } else {
            return(null);
        }

    }
}

HallOfFame.propTypes = propTypes;
export default HallOfFame;
