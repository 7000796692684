import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dice from "./Dice";

const propTypes = {
    dices: PropTypes.array,
    diceTotalScore: PropTypes.number,
};

class DicesAside extends Component {
    renderDices(dices) {
        if (dices) {
            return dices.map((dice, index) => {
                return(
                    <Dice dice={dices[index]} key={index}/>
                );
            });
        }
    }

    render() {
        return (
            <div className="DicesAside">
                {this.renderDices(this.props.dices)}

                <div className="DicesAsideScore">Score
                    <span className="DicesAsideScoreLabel">{this.props.diceTotalScore}</span>
                </div>
            </div>

        );
    }
}

DicesAside.propTypes = propTypes;
export default DicesAside;