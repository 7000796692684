/*
 * action types
 */
import DominoObj from "../model/DominoObj";
import DiceObj from "../model/DiceObj";

export const THROW_DICE = 'THROW_DICE';
export const CHOOSE_DICE = 'CHOOSE_DICE';
export const GET_HIGH_SCORES = 'GET_HIGH_SCORES';
export const NEW_GAME = 'NEW_GAME';
export const CHOOSE_NUMBER_OF_PLAYERS = 'CHOOSE_NUMBER_OF_PLAYERS';
export const CHOOSE_DOMINO = 'CHOOSE_DOMINO';
export const NEW_PLAYER_TURN_CONFIRM = 'NEW_PLAYER_TURN_CONFIRM';
export const BUST_CONFIRM = 'BUST_CONFIRM';
export const HIGH_SCORES_FETCHED = 'HIGH_SCORES_FETCHED';



/*
 * action creators
 */

// min and max included
function randomIntFromInterval(min,max) {
    return Math.floor(Math.random()*(max-min+1)+min);
}

export function throwDice(nrOfDice) {
    const diceIndexes = [];
    for (let step = 0; step < nrOfDice; step++) {
        diceIndexes[step] = randomIntFromInterval(0, 5);
    }

    return {
        type: THROW_DICE,
        diceIndexes: diceIndexes
    }
}

export function chooseDice(dice) {
    return {
        type: CHOOSE_DICE,
        dice: dice
    }
}

export function getHighScores() {
    return {
        type: GET_HIGH_SCORES
    }
}

function setupDominoStack() {
    const result = [];
    for (let i = 21; i <= 36; i++) {
        const value = Math.ceil((i-20)/4);
        const index = result.length;
        result[index] = new DominoObj(index, i, value);
    }
    return result;
}

function setupDiceStack() {
    const initialDiceStack = [];
    for (let i = 0; i < 8; i++) {
        initialDiceStack[i] = new DiceObj('stack'+i, 0, false);
    }
    return initialDiceStack;
}

export function newGame() {
    const dominoStack = setupDominoStack();
    return {
        type: NEW_GAME,
        dominoStack: dominoStack
    }
}

export function chooseNumberOfPlayers(nrOfPlayers) {
    return {
        type: CHOOSE_NUMBER_OF_PLAYERS,
        data: nrOfPlayers
    }
}

export function newPlayerTurnConfirm() {
    return {
        type: NEW_PLAYER_TURN_CONFIRM,
        diceStack: setupDiceStack()
    }
}

export function bustConfirm() {
    return {
        type: BUST_CONFIRM,
    }
}

export function chooseDomino(index) {
    return {
        type: CHOOSE_DOMINO,
        data: index
    }
}
