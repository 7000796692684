import React, { Component } from 'react';
import PropTypes from "prop-types";
import {TURN_BUST} from "../model/GameStates";

const propTypes = {
    status: PropTypes.string,
    confirm: PropTypes.func,
};

class TurnBust extends Component {

    render() {
        if (this.props.status === TURN_BUST) {
            return (
                <div className="PlayerTurnModal">
                    <h1>OEPS....bust!</h1>
                    <button onClick={this.props.confirm}>OK</button>
                </div>

            );
        } else {
            return(null);
        }
    }
}

TurnBust.propTypes = propTypes;
export default TurnBust;
