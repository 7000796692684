import {all, call, put, takeEvery, takeLatest} from 'redux-saga/effects'
import {HIGH_SCORES_FETCHED} from "../actions/actions";

// Our worker Saga: will perform the async fetch of high scores
export function* fetchHighScores() {
    const data = yield fetch('http://regenwormen.watermeloen.nl/data/halloffame.json')
        .then(response => response.json());
    yield put({ type: HIGH_SCORES_FETCHED, data: data });
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchFetchHighScores() {
    yield takeLatest('GET_HIGH_SCORES', fetchHighScores);
}


// function* helloSaga() {
//     console.log('Hello Sagas!');
// }

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        watchFetchHighScores()
    ])
}