// Dice index   -> 0 = worm (value = 5)
//              -> 1..5 label === value
// Dice ID: unique for each dice, given when thrown and does not change
class DiceObj {
    constructor(id, index, canBeChosen) {
        this.id = id;
        this.index = index;
        this.canBeChosen = canBeChosen;
    }

    getId = () => {
        return this.id;
    }

    getType = () => {
        return this.index;
    }

    getValue() {
        return this.index === 0 ? 5 : this.index;
    }

    getClassName() {
        return this.index === 0 ? 'worm' : `d${this.index}`;
    }

    getCanBeChosen() {
        return this.canBeChosen;
    }

    isWorm() {
        return this.index === 0;
    }
}

export default DiceObj;