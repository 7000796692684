import React, {Component} from 'react';
import PropTypes from "prop-types";
// import {GAME_FINISHED} from "../model/GameStates";
import HallOfFame from "./HallOfFame";
import ChoosePlayers from "./ChoosePlayers";
import {BEFORE_START, CHOOSE_PLAYERS} from "../model/GameStates";

const propTypes = {
    status: PropTypes.string,
    highScores: PropTypes.array,
    newGame: PropTypes.func,
    chooseNumberOfPlayers: PropTypes.func
};


class GameStatus extends Component {

    render() {
        const {status, highScores, chooseNumberOfPlayers, newGame} = this.props;
        if (status === BEFORE_START || status === CHOOSE_PLAYERS) {
            return (
                <div className={`GameStatus ${this.props.status}`}>
                    <HallOfFame status={status} highScores={highScores} newGame={newGame}/>
                    <ChoosePlayers status={status} chooseNumberOfPlayers={chooseNumberOfPlayers}/>
                </div>
            );
        } else {
            return(null);
        }
    }
}

GameStatus.propTypes = propTypes;
export default GameStatus;
