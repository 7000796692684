import React, {Component} from 'react';
import PropTypes from "prop-types";
import PlayerObj from '../model/PlayerObj';
import Domino from "./Domino";

const propTypes = {
    player: PropTypes.instanceOf(PlayerObj),
    isPlayerTurn: PropTypes.bool
};

class Player extends Component {

    renderDominos(player) {
        return player.getDominos().map((domino, index) => {
            return(<Domino domino={domino} key={index}/>);
        });
    }

    render() {
        return (
            <div className={`Player PlayerTurn${this.props.isPlayerTurn}`}>
                <h1>Speler {this.props.player.getNumber()}</h1>
                {this.renderDominos(this.props.player)}
            </div>

        );
    }
}

Player.propTypes = propTypes;
export default Player;
