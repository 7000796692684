export function containsDiceType(dices, type) {
    let found = false;
    for (let i = 0; i < dices.length; i++) {
        if (dices[i].getType() === type) {
            found = true;
            break;
        }
    }
    return found;
}

export function containsSelectableDices(dices) {
    let found = false;
    for (let i = 0; i < dices.length; i++) {
        if (dices[i].getCanBeChosen()) {
            found = true;
            break;
        }
    }
    return found;
}