import React, { Component } from 'react';
import PropTypes from "prop-types";

const propTypes = {
    score: PropTypes.object,
};

class HighScore extends Component {

    render() {
        const {score} = this.props;
        return (
            <div>{score.PlayerName} - {score.Score}</div>
        );
    }
}

HighScore.propTypes = propTypes;
export default HighScore;
