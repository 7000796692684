import React, {Component} from 'react';
import PropTypes from "prop-types";
import Player from "./Player";

const propTypes = {
    players: PropTypes.array,
    indexPlayerWhosTurn: PropTypes.number
};

class PlayerContainer extends Component {

    renderPlayers() {
        if (!this.props.players) return null;

        return this.props.players.map((player, index) => {
            return(<Player player={player} isPlayerTurn={this.props.indexPlayerWhosTurn === player.getIndex()} key={index}/>);
        });
    }

    render() {
        return (
            <div className="PlayerContainer">
                {this.renderPlayers()}
            </div>
        );
    }
}

PlayerContainer.propTypes = propTypes;
export default PlayerContainer;
